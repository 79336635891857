<template>
  <div style="width: 100%; float: left; padding-top: 20px; padding-left: 1%">
    <a style="width: 20%; float: left; text-align: left">
      <Icon type="ios-arrow-dropleft" size="25" @click="return_list" />
    </a>

    <div
      style="
        position: absolute;
        right: 1%;
        width: 7%;
        float: left;
        margin-bottom: 30px;
      "
    >
      <Tooltip
        content="You can report an illegal or unacceptable activity."
        placement="top-end"
        max-width="200"
      >
        <Button long type="primary" @click="modalReport = true">Report</Button>
      </Tooltip>
      <Modal
        v-model="modalReport"
        ok-text="OK"
        cancel-text="Cancel"
        title="Report"
        @on-ok="submitReport"
        :loading="true"
      >
        <div>
          <div>
            <p style="display: inline; font-size: 1.025rem">Applicant:</p>
            {{ userName }}
          </div>
          <div style="padding-top: 5px">
            <p style="font-size: 1.025rem">Specific Reason：</p>
            <div>
              <span style="color: darkgray"
                >You should describe the situation you meet.</span
              >
              <Input v-model="reportReason" type="textarea"> </Input>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    <div
      style="
        width: 63%;
        float: left;
        text-align: right;
        font-size: 1.105rem;
        font-weight: 500;
      "
    >
      <count-d v-if="join.status === 'untreated'" v-bind:end-date="finishTime">
      </count-d>
      <!--<span v-if="formLeft.processType===0" style="color:#e9ab01">
                This application is pending . . .
            </span>-->
      <span v-if="join.status === 'pass'" style="color: #13af5f">
        This application has been approved.
      </span>
      <span v-if="join.status === 'refuse'">
        This application has been rejected.
      </span>
    </div>
    <Card
      style="
        width: 80%;
        float: left;
        margin-left: 3%;
        padding: 10px 5%;
        margin-top: 30px;
        margin-bottom: 50px;
      "
    >
      <div :model="formLeft" style="text-align: left">
        <div class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              display: inline;
              padding-right: 15px;
            "
          >
            Applicant:
          </div>
          <span style="display: inline">{{ userName }}</span>

          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              display: inline;
              margin-left: 30%;
            "
          >
            Credit points:
          </div>
          <span style="display: inline">{{ credit }}</span>
        </div>

        <div class="join_table_unit">
          <div
            style="
              margin-bottom: 10px;
              font-size: 1.025rem;
              float: left;
              width: 100%;
              font-weight: 500;
              display: inline;
              padding-right: 15px;
            "
          >
            Related module:
          </div>
          <Button
            v-for="items in this.join.moduleList"
            style="margin-right: 10px"
          >
            {{ items }}
          </Button>
        </div>

        <div class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              display: inline;
              padding-right: 15px;
            "
          >
            Related Code Percentage:
          </div>
          <span style="display: inline">{{ join.relatedPercentage }}%</span>
        </div>

        <div class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              display: inline;
              padding-right: 15px;
            "
          >
            Estimated Code Percentage for Permission:
          </div>
          <span>{{ join.estimatedPercentage }} %</span>
        </div>

        <div class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              display: inline;
              padding-right: 15px;
            "
          >
            Type:
          </div>
          <p style="font-size: 1rem; font-weight: 400; display: inline">
            {{ join.type }}
          </p>
        </div>

        <div v-if="join.type === 'add new feature'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Function summary:
          </div>
          <p>{{ join.functionSummary }}</p>
        </div>
        <div v-if="join.type === 'add new feature'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Description (user view):
          </div>
          <p style="font-size: 14px; color: darkgray">
            Please provide a concrete description from the perspective of users.
          </p>
          <p style="font-size: 14px; color: darkgray">
            You should give some examples to illustrate this new feature.
          </p>

          <p>{{ join.userViewSescription }}</p>
        </div>
        <div v-if="join.type === 'add new feature'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Feature Technical Plan:
          </div>
          <p style="font-size: 14px; color: darkgray">
            If you can't give technical plan,
            <a :href="'/#/furtherInfo?id=' + projectId"
              >you can ask for more detail</a
            >.
          </p>

          <p>{{ join.technicalPlan }}</p>
        </div>
        <!-- <div class="join_table_unit">
                     <Radio v-model="read_tag">Radio</Radio>
                 </div>-->
        <div v-if="join.type === 'fix bug'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Bug description:
          </div>
          <p style="font-size: 14px; color: darkgray">
            You can gives a brief overview and don't leave out facts.
          </p>

          <p>{{ join.bugDescription }}</p>
        </div>

        <div v-if="join.type === 'fix bug'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Environment version:
          </div>
          <p style="font-size: 14px; color: darkgray">
            Environment description / version information
          </p>
          <p>{{ join.environmentVersion }}</p>
        </div>

        <div v-if="join.type === 'fix bug'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Bug route case:
          </div>
          <p style="font-size: 14px; color: darkgray">
            One of the very best ways you can report a bug is by showing it to
            us! Show us the same thing again and demonstrate the thing that goes
            wrong
          </p>
          <p>{{ join.bugRouteCase }}</p>
        </div>

        <div v-if="join.type === 'fix bug'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Expected results:
          </div>
          <p style="font-size: 14px; color: darkgray"></p>
          <p>{{ join.expectedResults }}</p>
        </div>

        <div v-if="join.type === 'fix bug'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Screenshot:
          </div>
          <div
            v-for="(items, index) in join.screenshotList"
            style="
              width: 100px;
              height: 100px;
              float: left;
              margin-right: 15px;
              border: 1px solid darkgray;
            "
          >
            <!--<img :src="items.src" />-->
            <img :src="items" height="98" width="98" />
            <!--<div class="demo-upload-list-cover">
                            <Icon type="ios-eye-outline" @click.native="handleView(items.name)"></Icon>
                            <Icon type="ios-trash-outline" @click.native="handleRemove(items)"></Icon>
                        </div>-->
            <div
              style="
                text-align: center;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 500;
                font-size: 0.83rem;
              "
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>

        <div v-if="join.type === 'fix bug'" class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Fix plan:
          </div>
          <p style="font-size: 14px; color: darkgray">
            If you don't have enough information to give fix plan, you can apply
            for <a :href="'/#/furtherInfo?id=' + projectId">more detail</a>.
          </p>
          <p>{{ join.fixPlan }}</p>
        </div>

        <div class="join_table_unit">
          <div
            style="
              font-size: 1.025rem;
              font-weight: 500;
              padding-right: 15px;
              margin-bottom: 5px;
            "
          >
            Test case description:
          </div>
          <p style="font-size: 14px; color: darkgray">
            Please provide a formal description about changes after your
            contribution !
          </p>
          <p>{{ join.testCase }}</p>
        </div>
      </div>
      <Divider></Divider>
      <div v-if="overdue">This join request has expired.</div>
      <div v-else>
        <div v-if="!voteAlready" style="width: 100%; float: left">
          <div
            style="
              width: 20%;
              float: left;
              margin-bottom: 40px;
              margin-left: 10%;
            "
          >
            <Button long type="primary" @click="myApprove()"> Approve</Button>
          </div>
          <div
            style="
              width: 20%;
              float: left;
              margin-bottom: 40px;
              margin-left: 5%;
            "
          >
            <Button @click="myReject()"> Reject</Button>
          </div>
          <div>
            <Button
              style="
                width: 20%;
                float: left;
                margin-bottom: 40px;
                margin-left: 15%;
              "
              type="primary"
              @click="confer"
              >Modify permission
            </Button>
          </div>
        </div>
        <div v-else>
          <div v-if="choice === 'approve'">
            You have already voted to approve.
          </div>
          <div v-if="choice === 'reject'">
            You have already voted to reject.
          </div>
        </div>
      </div>

      <div v-if="rgType === 0" style="margin-top: 20px; text-align: left">
        <!--                <Button type="primary" @click="submit">Approve</Button>-->
        <Modal v-model="modal1" @on-ok="approveOk" @on-cancel="cancel">
          <p slot="header" style="text-align: left">
            <span>Approve confirmation</span>
          </p>
          <div style="padding-top: 0px">
            <div>
              <p
                style="font-size: 1.015rem; font-weight: 400; text-align: left"
              >
                select share permission
              </p>
              <div id="treelocation">
                <half-tree
                  v-if="flag"
                  :data3="data3"
                  :branch="branch"
                  :project-id="projectId"
                  :labels="labels"
                  path=""
                ></half-tree>
                <div v-else>Waiting</div>
              </div>
              <span style="font-size: 0.85rem; color: gray">
                <Icon color="#D4AC0D" type="ios-warning-outline" size="22" />
                <p style="display: inline">
                  Your commit may work or not work at once.
                </p>
                <p>
                  If your commit don't work, you can call for a vote in the
                  discussion forum.
                </p>
              </span>
            </div>
          </div>
        </Modal>
        <!--                <Button style="margin-left: 50px" @click="reject">Reject</Button>-->
        <Modal
          v-model="modal3"
          title="Rejection"
          @on-ok="rejectOk"
          @on-cancel="cancel"
        >
          <div>
            <div>
              <p style="display: inline">Reject reason:</p>
              <Select v-model="reject_reason" style="width: 200px">
                <Option
                  v-for="item in reasonList"
                  :value="item.value"
                  :key="item.value"
                >
                  {{ item.label }}
                </Option>
              </Select>
              <p
                v-if="reject_reason === 'no reason'"
                style="
                  padding-top: 10px;
                  padding-left: 10px;
                  font-size: 0.875rem;
                  color: #e9ab01;
                "
              >
                You have only {{ reject_time }} chances to reject like this!
              </p>
            </div>
            <div style="padding-top: 20px">
              <p>specific reason：</p>
              <Input
                v-model="further_reason"
                placeholder="add more info"
                style="margin-top: 10px; width: 80%; margin-left: 10%"
              />
            </div>
          </div>
        </Modal>

        <!--                <Button style="margin-right: 5%;float: right" type="primary" @click="confer">Modify permission</Button>-->
        <Modal
          v-model="modal2"
          title="Make a discussion with the applicant"
          @on-ok="ok2"
          @on-cancel="cancel"
        >
          <p>Please give your own plan, and add some description on it</p>
          <Tree
            :data="related_module"
            :render="renderContent"
            class="demo-tree-render"
            show-checkbox
            expand="true"
            multiple
          >
          </Tree>
          <Input type="textarea"></Input>
        </Modal>
      </div>
    </Card>

    <Divider> </Divider>
    <div
      style="width: 100%; float: left; margin-bottom: 50px"
      v-if="join.count === 2"
    >
      <div
        style="
          width: 100%;
          float: left;
          font-size: 1.125rem;
          padding-bottom: 40px;
          font-weight: 500;
          text-align: left;
        "
      >
        Test cases and other supplemental information
      </div>
      <div style="width: 90%; float: left; margin-left: 6%; text-align: left">
        <div
          style="
            font-size: 1.015rem;
            font-weight: 500;
            padding-right: 15px;
            margin-bottom: 5px;
          "
        >
          Test case description:
        </div>
        <p
          style="
            padding: 10px 0%;
            width: 90%;
            font-size: 14px;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
            float: left;
          "
        >
          {{ join.testCase }}
        </p>
      </div>
      <div style="width: 90%; float: left; margin-left: 6%; text-align: left">
        <div
          style="
            font-size: 1.025rem;
            font-weight: 500;
            padding-right: 15px;
            margin-bottom: 5px;
          "
        >
          Test case:
        </div>
        <div
          style="width: 90%; float: left; margin-bottom: 30px; margin-top: 20px"
        >
          <Table border :columns="columns6" :data="data6"></Table>
        </div>
      </div>
      <div style="width: 100%; float: left" v-if="rgType === 0">
        <div style="width: 15%; float: left; margin-left: 10%">
          <Button type="primary" long @click="submit"> Approve123 </Button>
        </div>
        <div style="width: 15%; float: left; margin-left: 30%">
          <Button long @click="reject"> Reject </Button>
        </div>
      </div>
    </div>
    <!-- voting detail-->
    <Divider></Divider>
    <div style="width: 90%; float: left; text-align: left">
      <div
        style="
          width: 90%;
          float: left;
          text-align: left;
          font-size: 1.066rem;
          margin-bottom: 20px;
        "
      >
        The permissions will be open for this applicant are as follows.
      </div>
      <Card
        style="
          width: 60%;
          float: left;
          margin-left: 15%;
          margin-bottom: 30px;
          padding-left: 5%;
        "
      >
        <Tree
          :data="fileTree"
          :render="renderContent2"
          :load-data="loadData"
          class="demo-tree-render"
          expand="true"
        >
        </Tree>
      </Card>
    </div>
    <!--        <div style="width: 100%; float:left; margin-left:0% ;padding-left:  0%;padding-right:5%;padding-bottom: 50px">-->
    <!--            <div style="width: 100%;float: left;font-size: 1.125rem;padding-bottom: 40px; font-weight: 500; text-align: left">-->
    <!--                Voting details-->
    <!--            </div>-->
    <!--            <voting-pic v-bind:approve="approve_share" v-bind:pending="pending_share"-->
    <!--                        v-bind:reject="reject_share"></voting-pic>-->

    <!--            &lt;!&ndash; <div style="width: 100%;float: left;min-height: 200px" id="vote_now" ref="voting_data">-->
    <!--             </div>&ndash;&gt;-->
    <!--            <div style="width: 90%;float: left;text-align: left;">-->
    <!--                <div style="width: 90%;float: left;text-align: left;font-size: 1.066rem;margin-bottom: 20px">-->
    <!--                    The permissions will be open for this applicant are as follows.-->
    <!--                </div>-->
    <!--                <Card style="width: 60%; float:left;margin-left: 15%;padding-left: 5%">-->
    <!--                    <Tree-->
    <!--                            :data="related_module2"-->
    <!--                            :render="renderContent2"-->
    <!--                            class="demo-tree-render"-->
    <!--                            expand="true">-->
    <!--                    </Tree>-->
    <!--                </Card>-->
    <!--            </div>-->
    <!--        </div>-->
    <div v-show="voteAlready" style="width: 80%; float: left; margin-top: 30px">
      <Divider></Divider>
      <div>
        <div
          style="
            width: 50%;
            float: left;
            font-size: 1.25rem;
            text-align: left;
            font-weight: 500;
            margin-bottom: 20px;
            display: inline;
          "
        >
          Voting details
          <Tag style="margin-left: 3px" type="dot" color="warning">{{
            voteState
          }}</Tag>
        </div>
        <div
          style="
            width: 50%;
            float: left;
            font-size: 1rem;
            text-align: right;
            font-weight: 500;
            margin-bottom: 20px;
            display: inline;
          "
        >
          <CountD v-bind:end-date="finishTime"></CountD>
        </div>
      </div>
      <voting-pic
        v-bind:reject="reject"
        v-bind:pending="pending"
        v-bind:approve="approve"
      ></voting-pic>
      <Divider></Divider>
      <div
        style="
          width: 100%;
          float: left;
          margin-left: 0%;
          padding-left: 0%;
          padding-right: 5%;
          padding-bottom: 50px;
        "
      >
        <div
          style="
            width: 50%;
            float: left;
            padding-right: 20%;
            margin-bottom: 20px;
            text-align: right;
          "
        >
          <Input search placeholder="find center person"></Input>
        </div>
        <div
          style="
            width: 50%;
            float: right;
            margin-bottom: 40px;
            text-align: right;
          "
        >
          <!--                            <Dropdown style="text-align: left">-->
          <!--                                <Button type="primary">-->
          <!--                                    Sort-->
          <!--                                    <Icon type="ios-arrow-down"></Icon>-->
          <!--                                </Button>-->
          <!--                                <DropdownMenu slot="list">-->
          <!--                                    <DropdownItem>Latest</DropdownItem>-->
          <!--                                    <DropdownItem>Share</DropdownItem>-->
          <!--                                </DropdownMenu>-->
          <!--                            </Dropdown>-->
          <Button style="margin-left: 12%" type="primary" @click="addComment"
            >Add</Button
          >
          <Modal
            v-model="commentModal"
            title="Make a discussion with the applicant"
            @on-ok="commentOk"
          >
            <div>
              <div>
                <Input
                  v-model="commentContent"
                  type="textarea"
                  placeholder="add more info...."
                  style="margin-top: 10px; width: 80%; margin-left: 10%"
                />
              </div>
            </div>
          </Modal>
        </div>
        <Divider></Divider>
        <div style="width: 100%; float: left">
          <List item-layout="vertical">
            <div
              v-for="item in voteCommentData"
              :key="item.title"
              style="width: 100%; float: left; min-height: 100px"
            >
              <div style="width: 30%; padding-bottom: 30px; float: left">
                <div style="text-align: center; width: 100%; float: left">
                  <Avatar icon="ios-person" size="70" />
                </div>
                <div
                  style="
                    text-align: center;
                    width: 100%;
                    float: left;
                    margin-top: 20px;
                    font-weight: 500;
                    font-size: 1.025rem;
                  "
                >
                  {{ item.userName }}
                </div>
              </div>
              <List item-layout="vertical">
                <ListItem
                  style="
                    text-align: left;
                    width: 70%;
                    float: left;
                    word-wrap: break-word;
                    word-break: normal;
                  "
                >
                  {{ item.content }}
                </ListItem>
                <ListItem
                  style="
                    text-align: right;
                    width: 70%;
                    height: 10px;
                    float: left;
                  "
                >
                  {{ item.time }}
                </ListItem>
              </List>
              <Divider></Divider>
            </div>
          </List>
        </div>
      </div>
    </div>
    <Modal v-model="commentModal" title="Leave a comment" @on-ok="commentOk">
      <div>
        <div>
          <Input
            v-model="commentContent"
            type="textarea"
            placeholder="add more info...."
            style="margin-top: 10px; width: 80%; margin-left: 10%"
          />
        </div>
      </div>
    </Modal>

    <!--        <div style="width: 100%; float:left; margin-left:0% ;padding-left:  0%;padding-right:5%;padding-bottom: 50px">-->
    <!--            <div style="width: 100%;float: left;font-size: 1.125rem;padding-bottom: 30px; font-weight: 500; text-align: left">-->
    <!--                Discussions-->
    <!--            </div>-->
    <!--            <div style="width: 50%;float:left;padding-right:20%;margin-bottom: 20px;text-align: right">-->
    <!--                <Input search placeholder="find center person"></Input>-->
    <!--            </div>-->
    <!--            <div style="width: 50%;float: right;padding-right:20%;margin-bottom: 40px;text-align: right">-->
    <!--                <Dropdown style="text-align: left">-->
    <!--                    <Button type="primary">-->
    <!--                        Sort-->
    <!--                        <Icon type="ios-arrow-down"></Icon>-->
    <!--                    </Button>-->
    <!--                    <DropdownMenu slot="list">-->
    <!--                        <DropdownItem>Latest</DropdownItem>-->
    <!--                        <DropdownItem>Share</DropdownItem>-->
    <!--                    </DropdownMenu>-->
    <!--                </Dropdown>-->
    <!--                <Button style="margin-left: 12%" type="primary" @click="md4">Add</Button>-->
    <!--                <Modal v-model="modal4"-->
    <!--                       title="Make a discussion with the applicant"-->
    <!--                       @on-ok="ok2"-->
    <!--                       @on-cancel="cancel">-->
    <!--                    <div>-->
    <!--                        <div>-->
    <!--                            <Input v-model="further_reason" type="textarea" placeholder="add more info"-->
    <!--                                   style="margin-top:10px; width: 80%; margin-left: 10%"/>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </Modal>-->
    <!--            </div>-->
    <!--            <Divider></Divider>-->
    <!--            <div style="width: 100%; padding-right:20%;float: left">-->
    <!--                <List item-layout="vertical">-->
    <!--                    <div v-for="item in data" :key="item.title" style="width: 100%;float: left; min-height: 100px">-->
    <!--                        <div style="width: 30%;padding-bottom:30px;float: left">-->
    <!--                            <div style="width: 100%;float: left">-->
    <!--                                <Avatar icon="ios-person" size="70"/>-->
    <!--                            </div>-->
    <!--                            <div style="width: 100%;float: left;margin-top: 20px;font-weight: 500;font-size: 1.025rem">-->
    <!--                                {{item.user_name}}-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                        <div style="width: 60%;float: left">-->
    <!--                            {{ item.content }}-->
    <!--                        </div>-->
    <!--                        <Divider></Divider>-->
    <!--                    </div>-->
    <!--                </List>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>

<script>
import CountD from "./countD";
import VotingPic from "./votingPic";
import HalfTree from "./halfTree";
import Vue from "vue";

export default {
  name: "checkJoinInfo",
  components: { HalfTree, VotingPic, CountD },
  data() {
    return {
      modalReport: false,
      reportReason: "",
      reject: 0,
      pending: 0,
      approve: 0,
      finishTime: "",
      voteAlready: false,
      branch: "master",
      path: "/",
      tempNode: "",
      flag: false,
      rgType: 0,
      contributionId: "",
      id: "",
      projectId: "",
      overdue: false,
      projectName: "",
      userName: "",
      voteId: "",
      voteCommentData: [],
      commentContent: "",

      credit: 0,
      userId: "",
      formLeft: {
        appli_type: "fix bug",
        projectName: "",
      },
      join: {
        bugDescription: "",
        bugRouteCase: "",
        environmentVersion: "",
        estimatedPercentage: 0,
        expectedResults: "",
        fixPlan: "",
        functionSummary: "",
        moduleList: [],
        //模块列表List《String》
        id: "",
        status: "",
        projectId: "",
        projectName: "",
        relatedPercentage: 0, //相关比例
        reply: "", //拒绝的理由
        screenshotList: [],
        technicalPlan: "",
        testCase: "", //测试用例描述
        type: "fix bug", //fix function
        userId: "", //用户ID
        userViewSescription: "", //截图地址列表
        username: "", //用户名
        //status: pass reject untreated
      },
      modal1: false, //approve
      modal2: false, //discuss
      modal3: false, //rejcet
      modal4: false,
      commentModal: false,
      reject_reason: "",
      further_reason: "",
      reject_time: 3,
      reject_share: 3000,
      approve_share: 4000,
      pending_share: 1200,
      end_data: null, //投票结束时间
      start_tag: false, //投票是否开始
      fileTree: [],

      related_module: [],
      related_module2: [
        {
          title: "parent 1",
          expand: true,
          children: [
            {
              title: "parent 1-1",
              expand: true,
              children: [
                {
                  title: "leaf 1-1-1",
                },
                {
                  title: "leaf 1-1-2",
                },
              ],
            },
            {
              title: "parent 1-2",
              expand: true,
              children: [
                {
                  title: "leaf 1-2-1",
                },
                {
                  title: "leaf 1-2-1",
                },
              ],
            },
          ],
        },
      ],
      columns1: [
        {
          title: "Type",
          key: "type",
        },
        {
          title: "Proportion",
          key: "proportion",
        },
      ],
      data1: [
        {
          type: "share to approve",
          proportion: 44,
        },
        {
          type: "share to reject ",
          proportion: 33,
        },
        {
          type: "pending",
          proportion: 23,
        },
      ],
      reasonList: [
        { value: "no reason", label: "no reason" },
        { value: "I don't know her", label: "I don't know her" },
        { value: "nobody cares", label: "nobody cares" },
      ],
      labels: ["part1", "part2", "part3"],
      data3: [],
      data: [
        {
          title: "This is title 1",
          user_name: "jkkk",
          description:
            "This is description, this is description, this is description.",
          //avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
          content:
            "This is the content, this is the content, this is the content, this is the content.",
        },
        {
          title: "This is title 2",
          user_name: "jkkk",
          description:
            "This is description, this is description, this is description.",
          //avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
          content:
            "This is the content, this is the content, this is the content, this is the content.",
        },
        {
          title: "This is title 3",
          user_name: "jkkk",
          description:
            "This is description, this is description, this is description.",
          //avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
          content:
            "This is the content, this is the content, this is the content, this is the content.",
        },
      ],
      functions: [
        { key: "print1", value: "print1" },
        { key: "print2", value: "print2" },
        { key: "print3", value: "print3" },
      ],
      columns6: [
        {
          title: "Id",
          key: "id",
        },
        {
          title: "InputType",
          key: "inputType",
        },
        {
          title: "Input",
          key: "input",
        },
        {
          title: "OutputType",
          key: "outputType",
        },
        {
          title: "Output",
          key: "output",
        },
      ],
      data6: [
        {
          id: 1,
          inputType: "text",
          input: "[2,3,4,5,67,76]",
          outputType: "text",
          output: "6",
        },
        {
          id: 2,
          inputType: "text",
          input: "[2,3]",
          outputType: "text",
          output: "2",
        },
        {
          id: 3,
          inputType: "text",
          input: "[2,3,4]",
          outputType: "text",
          output: "3",
        },
      ],
    };
  },
  methods: {
    initialData() {
      var Tself = this;
      this.id = this.$route.query.jid;
      this.jid = this.$route.query.jid;
      this.projectId = this.$route.query.id;
      this.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: "/join/findById",
        data: this.id,
      })
        .then(function (response) {
          //添加内容
          //这里要有申请的相关内容
          let data = response.data.data.items;
          Tself.projectName = data.projectName;
          Tself.userId = data.userId;
          Tself.userName = data.username;
          Tself.overdue = !data.latest;
          if (data.status === "untreated") {
            Tself.rgType = 0;
          } else {
            Tself.rgType = 1;
          }
          //添加用户相关信息？credit
          Tself.$axios
            .post("/user/findById", Tself.userId, {
              headers: {
                "Content-Type": "text/xml",
              },
            })
            .then((response2) => {
              let uf = response2.data.data.items;
              Tself.credit = parseInt(uf.credit);

              // 获取投票信息
              Tself.getVoteInforamtion();
            })
            .catch((error) => {});

          Tself.projectName = data.projectName;
          Tself.applicationNum = 1;
          Tself.join.bugDescription = data.bugDescription;
          Tself.join.bugRouteCase = data.bugRouteCase;
          Tself.join.environmentVersion = data.environmentVersion;
          Tself.join.estimatedPercentage = parseInt(data.estimatedPercentage);
          Tself.join.expectedResults = data.expectedResults;
          Tself.join.fixPlan = data.fixPlan;
          Tself.join.functionSummary = data.functionSummary;
          Tself.join.moduleList = data.moduleList;
          Tself.join.relatedPercentage = parseInt(data.relatedPercentage); //相关比例
          Tself.join.screenshotList = data.screenshotList;
          Tself.join.technicalPlan = data.technicalPlan;
          Tself.join.testCase = data.testCase; //测试用例描述
          Tself.join.type = data.type; //fix function
          Tself.join.userViewSescription = data.userViewSescription; //截图地址列表
          Tself.join.status = data.status;
          Tself.join.id = data.id;
          Tself.count = data.count;

          if (Tself.count === 2) {
            Tself.edit_tag = false;
          }

          let data2 = {
            relatedId: Tself.jid,
            type: "JoinProject",
            userId: Tself.getCookie("user_id"),
          };
          Tself.$axios({
            method: "post",
            url: "/vote/getVoteRecord",
            data: data2,
          })
            .then((response) => {
              let data = response.data.data.items;
              if (data === undefined || data === null || data === "") {
                Tself.voteAlready = false;
              } else {
                Tself.voteAlready = true;
                Tself.choice = data.choice;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },
    getVoteInforamtion() {
      let data = {
        relatedId: this.jid,
        type: "JoinProject",
      };
      this.$axios({
        method: "post",
        url: "/vote/getVoteInformation",
        data: data,
      })
        .then((response) => {
          let data = response.data.data.items;
          this.voteId = data.id;
          this.approve = data.approve;
          this.reject = data.reject;
          this.voteState = data.state;
          this.startTime = data.startTime;
          this.finishTime = data.finishTime;
          this.state = data.state;
          if (this.state === "Done") {
            this.voteAlready = true;
            Object.freeze(this.voteAlready);
          }
          this.getVoteCommentData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVoteCommentData() {
      this.$axios({
        method: "post",
        url: "/vote/getVoteComment",
        data: {
          voteId: this.voteId,
        },
      })
        .then((response) => {
          let data = response.data.data.items;
          this.voteCommentData = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    myApprove() {
      console.log(1);
      this.flag = true;
      this.modal1 = true;
    },
    submit() {
      this.$nextTick(function () {
        this.flag = true;
        this.modal1 = true;
      });
    },
    solveTreeFiles() {
      let Tself = this;
      var d1 = this.$route.query.id;
      let d2 = this.path;
      let d3 = this.branch;
      this.path = "";
      this.$axios
        .get(
          "/pit/getAllFile?projectId=" + d1 + "&relPath=" + d2 + "&branch=" + d3
        )
        .then(function (response) {
          var data23 = Array.from(response.data);
          Tself.fileTree = data23.map(function (item) {
            if (item.dir) {
              return {
                title: item.file,
                absolutePath: item.absolutePath,
                isDir: item.isDir,
                tag: item.tag,
                expand: false,
                commitDate: item.commitDate,
                message: item.message,
                dir: item.dir,
                loading: false,
                check: false,
                checkAll: false,
                reqPath: item.file, //用于请求的相对地址
                children: [], //用来添加文件夹下内容
              };
            }
            return {
              expand: false,
              title: item.file,
              absolutePath: item.absolutePath,
              isDir: item.isDir,
              tag: item.tag,
              commitDate: item.commitDate,
              message: item.message,
              dir: item.dir,
              check: false,
              checkAll: false,
              reqPath: d2 + item.file, //用于请求的相对地址
            };
          });
          // Tself.loadAllTreeNode();
        })
        .catch(function (error) {
          Tself.isMember = false;
          console.log(error);
        });
    },
    loadData(item, callback) {
      let Tself = this;
      let d1 = this.$route.query.id;
      let d2 = item.reqPath; //这里用的文件夹的相对地址
      let d3 = this.branch;
      this.$axios
        .get(
          "/pit/getAllFile?projectId=" + d1 + "&relPath=" + d2 + "&branch=" + d3
        )
        .then(function (response) {
          var dto = [];
          var data23 = Array.from(response.data);
          dto = data23.map(function (item) {
            if (item.dir) {
              return {
                loading: false,
                title: item.file,
                absolutePath: item.absolutePath,
                isDir: item.isDir,
                tag: item.tag,
                commitDate: item.commitDate,
                message: item.message,
                dir: item.dir,
                check: false,
                checkAll: false,
                reqPath: d2 + "/" + item.file, //用于请求的相对地址
                children: [], //用来添加文件夹下内容
              };
            }
            return {
              title: item.file,
              absolutePath: item.absolutePath,
              isDir: item.isDir,
              tag: item.tag,
              check: false,
              checkAll: false,
              commitDate: item.commitDate,
              message: item.message,
              dir: item.dir,
              reqPath: d2 + "/" + item.file, //用于请求的相对地址
            };
          });
          callback(dto);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadTreeNode(node) {
      let Tself = this;
      let d1 = this.$route.query.id;
      let d2 = node.reqPath; //这里用的文件夹的相对地址
      let d3 = this.branch;
      this.$axios
        .get(
          "/pit/getAllFile?projectId=" + d1 + "&relPath=" + d2 + "&branch=" + d3
        )
        .then(function (response) {
          var dto = [];
          var data23 = Array.from(response.data);
          dto = data23.map(function (item) {
            if (item.dir) {
              return {
                loading: false,
                title: item.file,
                absolutePath: item.absolutePath,
                isDir: item.isDir,
                tag: item.tag,
                commitDate: item.commitDate,
                message: item.message,
                dir: item.dir,
                check: false,
                checkAll: false,
                reqPath: d2 + "/" + item.file, //用于请求的相对地址
                children: [], //用来添加文件夹下内容
              };
            }
            return {
              title: item.file,
              absolutePath: item.absolutePath,
              isDir: item.isDir,
              tag: item.tag,
              check: false,
              checkAll: false,
              commitDate: item.commitDate,
              message: item.message,
              dir: item.dir,
              reqPath: d2 + "/" + item.file, //用于请求的相对地址
            };
          });
          Tself.tempNode = dto;
          console.log("dto");
          console.log(Tself.tempNode);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadAllTreeNode() {
      let Tself = this;
      let stack = [];
      for (let i = 0; i < this.fileTree.length; i++) {
        stack.push(this.fileTree[i]);
      }
      while (stack.length > 0) {
        let nowNode = stack.pop();
        nowNode.expand = true;
        let d1 = Tself.$route.query.id;
        let d2 = nowNode.reqPath; //这里用的文件夹的相对地址
        let d3 = Tself.branch;
        this.$axios
          .get(
            "/pit/getAllFile?projectId=" +
              d1 +
              "&relPath=" +
              d2 +
              "&branch=" +
              d3
          )
          .then(function (response) {
            var dto = [];
            var data23 = Array.from(response.data);
            dto = data23.map(function (item) {
              if (item.dir) {
                return {
                  loading: false,
                  title: item.file,
                  absolutePath: item.absolutePath,
                  isDir: item.isDir,
                  tag: item.tag,
                  commitDate: item.commitDate,
                  message: item.message,
                  dir: item.dir,
                  check: false,
                  checkAll: false,
                  reqPath: d2 + "/" + item.file, //用于请求的相对地址
                  children: [], //用来添加文件夹下内容
                };
              }
              return {
                title: item.file,
                absolutePath: item.absolutePath,
                isDir: item.isDir,
                tag: item.tag,
                check: false,
                checkAll: false,
                commitDate: item.commitDate,
                message: item.message,
                dir: item.dir,
                reqPath: d2 + "/" + item.file, //用于请求的相对地址
              };
            });
            let nextNode = dto;
            if (
              nextNode !== null &&
              nextNode !== undefined &&
              nextNode !== ""
            ) {
              stack.push(nextNode);
              nowNode.children = nextNode;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    confer() {
      this.modal2 = true;
    },
    myReject() {
      this.modal3 = true;
    },
    reject() {
      this.modal3 = true;
    },
    return_list() {
      this.$router.push("/checkJoinList?id=" + this.projectId);
    },
    md4() {
      this.modal4 = true;
    },

    addComment() {
      this.commentModal = true;
    },
    approveOk() {
      let Tself = this;
      let voteData = {
        relatedId: this.jid,
        choice: "approve",
        type: "JoinProject",
        userId: this.getCookie("user_id"),
      };
      this.$axios({
        method: "post",
        url: "/vote/submitVote",
        data: voteData,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "vote success") {
            Tself.$Message.success(data);
            this.voteAlready = true;
            this.choice = "approve";
            this.getVoteInforamtion();
          } else {
            Tself.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rejectOk() {
      let Tself = this;
      let voteData = {
        relatedId: this.jid,
        choice: "reject",
        type: "JoinProject",
        userId: this.getCookie("user_id"),
      };
      this.$axios({
        method: "post",
        url: "/vote/submitVote",
        data: voteData,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "vote success") {
            Tself.$Message.success(data);
            this.voteAlready = true;
            this.choice = "reject";
            this.getVoteInforamtion();
          } else {
            Tself.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let data = {
        voteId: this.voteId,
        userId: this.getCookie("user_id"),
        content: "Reject:" + this.reject_reason + ". " + this.further_reason,
      };
      this.$axios({
        method: "post",
        url: "/vote/addVoteComment",
        data: data,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "comment success") {
            this.getVoteCommentData();
          } else {
            this.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    commentOk() {
      let data = {
        voteId: this.voteId,
        userId: this.getCookie("user_id"),
        content: this.commentContent,
      };
      this.$axios({
        method: "post",
        url: "/vote/addVoteComment",
        data: data,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "comment success") {
            this.$Message.success(data);
            this.getVoteCommentData();
          } else {
            this.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ok() {
      //todo
      //同意第一部分的提交
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/join/agree",
        data: {
          joinId: this.id,
          reply: " ",
        },
      })
        .then((response) => {
          console.log(response);
          this.$Message.success("OK!");
          this.end_data = new Date(Date.now() + 5 * 60 * 1000);
          this.start_tag = true;
          //更新join信息
          this.load_data();
        })
        .catch((error) => {
          console.log(error);
          this.$Message.error(error.message);
        });
    },
    ok2() {
      //todo
      //提交拒绝第一部分join的理由
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/join/refuse",
        data: {
          joinId: this.id,
          reply: this.data3,
        },
      })
        .then((response) => {
          this.$Message.success("OK!");
          //更新join信息
          this.load_data();
          this.end_data = new Date(Date.now() + 5 * 60 * 1000);
          this.start_tag = true;
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    cancel() {},
    async submitReport() {
      let arbitration = {
        projectId: this.$route.query.id,
        projectName: this.projectName,
        reason: this.reportReason,
        sourceId: this.$route.query.id,
        sourceType: "project",
        targetId: this.join.id,
        targetType: "user",
      };
      console.log(arbitration);
      try {
        let res = await this.$axios({
          method: "post",
          url: "/arbitration/addArbitration",
          data: arbitration,
        });
        console.log(res);
        this.$Message.success("success");
        this.modalReport = false;
      } catch (e) {
        console.log(e);
      }
    },
    draw_line_pic() {
      let v1 = document.getElementById("reject_line");
      let v2 = document.getElementById("approve_line");
      let v3 = document.getElementById("pending_line");
      let total = this.approve_share + this.reject_share + this.pending_share;
      let v1_num = this.approve_share.toFixed(3) / total.toFixed(3);
      let v2_num = this.reject_share.toFixed(3) / total.toFixed(3);
      let v3_num = this.pending_share.toFixed(3) / total.toFixed(3);
      v1.style.width = (100 * v2_num).toString() + "%";
      v2.style.width = (100 * v1_num).toString() + "%";
      v3.style.width = (100 * v3_num).toString() + "%";
    },
    setHalfCheckedNodes(key) {
      const node = this.$refs["lazyTree"].getNode(key);
      if (node) {
        // 此处应判断当前节点的checked属性是否为true，是则不必半选
        node.indeterminate = true;
      }
    },
    renderContent(h, { root, node, data }) {
      return h(
        "div",
        {
          style: {
            width: "100%",
          },
        },
        [
          h(
            "div",
            {
              style: {
                width: "100%",
                marginRight: "80px",
              },
            },
            [
              h("Icon", {
                props: {
                  type: "ios-paper-outline",
                },
                style: {
                  marginRight: "8px",
                  float: "left",
                },
              }),
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    marginRight: "8px",
                    float: "left",
                  },
                },
                data.title
              ),
              h(
                "div",
                {
                  style: {
                    float: "right",
                    marginRight: "20px",
                    display: "inline-block",
                  },
                },
                [
                  h(
                    "Select",
                    {
                      props: {
                        vModel: data.label,
                        size: "small",
                      },
                      style: {
                        width: "120px",
                        float: "right",
                      },
                    },
                    [
                      h("Option", "Print1"),
                      h("Option", "Print2"),
                      h("Option", "Print3"),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      );
    },
    renderContent2(h, { root, node, data }) {
      return h(
        "div",
        {
          style: {
            width: "100%",
          },
        },
        [
          h(
            "div",
            [
              h("Icon", {
                props: {
                  type: "ios-paper-outline",
                },
                style: {
                  marginRight: "18px",
                  size: "50",
                },
              }),
              h(
                "span",
                {
                  style: {
                    fontSize: "1.045rem",
                  },
                },
                data.title
              ),
            ],
            {
              style: {
                marginRight: "8px",
                width: "40px",
                textAlign: "left",
                float: "left",
                display: "inline",
              },
            }
          ),
        ]
      );
    },
    load_data() {},
    solveFiles() {
      this.solveFiles1();
    },
    solveFiles1() {
      this.contributionId = this.$cookies.get("contributionId");
      let Tself = this;
      let d4 = this.contributionId;
      let d1 = this.projectId;
      let d2 = this.path;
      let d3 = this.branch;
      this.path = "";
      this.$axios
        .get(
          "/pit/getAllFile?projectId=" + d1 + "&relPath=" + d2 + "&branch=" + d3
        )
        .then(function (response) {
          var data22 = Array.from(response.data);
          var all_files = data22.map(function (item) {
            if (item.dir) {
              return {
                file: item.file,
                absolutePath: item.absolutePath,
                isDir: item.isDir,
                tag: item.tag,
                canChoose: false, //权限关闭
                expand: false, //是否展开
                commitDate: item.commitDate,
                message: item.message,
                dir: item.dir,
                loading: false,
                check: false,
                checkAll: false,
                reqPath: item.file, //用于请求的相对地址
                childFile: [], //用来添加文件夹下内容
              };
            }
            return {
              expand: false,
              file: item.file,
              canChoose: false, //权限关闭
              absolutePath: item.absolutePath,
              isDir: item.isDir,
              tag: item.tag,
              commitDate: item.commitDate,
              message: item.message,
              dir: item.dir,
              pages: [],
              check: false,
              checkAll: false,
              reqPath: d2 + item.file, //用于请求的相对地址
            };
          });
          //开放权限
          Tself.$axios
            .get(
              "/pit/getAllFile?projectId=" +
                d4 +
                "&relPath=" +
                d2 +
                "&branch=" +
                d3
            )
            .then(function (response) {
              var dataToOpen = Array.from(response.data);
              for (var it1 of dataToOpen) {
                for (var it2 of all_files) {
                  if (it1.file === it2.file) {
                    it2.canChoose = Boolean(true); //开放权限
                    Tself.data3.push(it2);
                    break;
                  }
                }
              }
              console.log(Tself.data3);
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /* draw_pie(){
                 let myChart = this.$echarts.init(document.getElementById('vote_now'))
                 myChart.setOption(
                     this.option
                 );
                 window.addEventListener('resize', () => {
                     // 自动渲染echarts
                     myChart.resize();
                 })
             },*/
  },
  mounted() {
    this.initialData();
    this.solveFiles();
    this.solveTreeFiles();
    /*this.draw_pie()*/
  },
};
</script>

<style scoped>
.join_table_unit {
  padding-bottom: 40px;
}
</style>
